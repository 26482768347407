import Lightbox from './Lightbox';

document.addEventListener('DOMContentLoaded', () => {
  const $lightboxElements = document.querySelectorAll('.js-lightbox');
  if (!$lightboxElements.length) {
    return;
  }
  $lightboxElements.forEach(($lightbox) => {
    const lightbox = new Lightbox($lightbox, {
    });
    lightbox.mount();
  });
});

