import 'mdn-polyfills/NodeList.prototype.forEach';


export default class Lightbox {
  constructor(element) {
    this.element = element;
    this.buttons = this.element.querySelectorAll(".c-lightbox__button");
    this.images = document.querySelectorAll(".c-lightbox__image");
    this.changeActiveImage = this.changeActiveImage.bind(this);
  }

  mount() {
    this.buttons[0].classList.add("c-lightbox__button--active");
    for (let i = 0; i < this.buttons.length; i++) {
      const button = this.buttons[i];
      button.addEventListener("click", this.changeActiveImage);
    }
  }

  changeActiveImage(e) {
    for (let index = 0; index < this.images.length; index++) {
      const image = this.images[index];
      image.style.opacity="0";
    }
    for (let index = 0; index < this.buttons.length; index++) {
      const button = this.buttons[index];
      button.className="c-lightbox__button";
    }

    let selectedImage = e.target.id-1;
    this.images[selectedImage].style.opacity="1";
    this.buttons[selectedImage].classList.add("c-lightbox__button--active");
  }


}
